import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import useFetchAemBrspdContents from "../../../../shared/hooks/useFetchAemBrspdContents";
import ApiUrls from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";

type CommunicationPreferenceContentState = {
    communicationPreferenceContent: any;
    loading: boolean;
    error: any;
}

const initialState: CommunicationPreferenceContentState = {
    communicationPreferenceContent: null,
    loading: false,
    error: null,
};

const host = window?.location?.host;
const file = "communication-preferance.json";

export const getCommunicationPreferenceContent = createAsyncThunk(
    "get/communication-preferance/content",
    async () => {
        try {
            const response = await useFetchAemBrspdContents({
                [ApiUrls.aem_content]: `${file}?appName=${host}`,
            });
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

const communicationPreferenceSlice = createSlice({
    name: "communication-preference-content/Details",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCommunicationPreferenceContent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getCommunicationPreferenceContent.fulfilled, (state, action) => {
            state.communicationPreferenceContent = action.payload;
            state.loading = false;
            state.error = null;
        })
        builder.addCase(getCommunicationPreferenceContent.rejected, (state, action) => {
            errorConditionGTM(
                "BRSPD_Fiber_EC_Flow",
                "communication-preference-content",
                action?.error?.message,
                action?.type
            );
            state.communicationPreferenceContent = null;
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default communicationPreferenceSlice.reducer;