import { getAmHost } from "./utils";

const THIC= "Total Home Internet Controls"
const WIFI_EXTENDER= "WiFi extender"
const WIFI_EXTENDER_SHORT= "WiFi extender"
const GRANDFATHER_FLAG_2= false;
const GRANDFATHER_FLAG_1= false
const THIC_LAUNCH_DATE= "2025-01-17";
// const Addon= ["WiFI","tech","controls"];

/*
if ENABLE_THIC_WIFI_QA set it
*/ 
const ENABLE_THIC_WIFI_QA= getAmHost()==="BAU_QA2"?true:false;

export {THIC,WIFI_EXTENDER,WIFI_EXTENDER_SHORT,GRANDFATHER_FLAG_2,GRANDFATHER_FLAG_1,THIC_LAUNCH_DATE,ENABLE_THIC_WIFI_QA}


/* before march 7 for now but(17)  customerSince if enrolled Iq bundle - hide*/ 
/* after march 7 for now but(17)  customerSince enrolled normal ui */ 
/*grand father -2 new ui only */ 

