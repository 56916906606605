import React, { Suspense } from "react";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import { CookiesProvider } from "react-cookie";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ToastContainer } from "react-toastify";

import brightspeedTheme from "./brightspeedTheme";
import AllRoutes from "./Routes";
import Util from "../../shared/utils/Util";
import {persistor} from "./SharedContextStore/store";
import store from "./SharedContextStore/store";
import closeIcon from "./assets/images/tostCloseIcon.svg";
import { PersistGate } from 'redux-persist/integration/react';
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/styles/globals.scss";
import "./assets/styles/mui.scss";
import "./assets/styles/main.scss";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

function App() {
  const theme = brightspeedTheme;

  const ToastCloseButton = ({ closeToast }: any) => (
    <img
      onClick={closeToast}
      tabIndex={0}
      src={closeIcon}
      alt="close toast container"
      className="cursor-pointer tostCloseIcon"
    />
  );

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense
            fallback={
              <Stack spacing={1}>
                <Skeleton variant="rectangular" height={50} />
                <Stack direction="row">
                  <Skeleton
                    variant="rectangular"
                    width={"10vw"}
                    height={"90vh"}
                  />
                  <Skeleton
                    sx={{ ml: 1 }}
                    variant="rectangular"
                    width={"89vw"}
                    height={"90vh"}
                  />
                </Stack>
              </Stack>
            }
          >
            <Router>
              <AllRoutes />
            </Router>
          </Suspense>
          <CssBaseline />
          <ToastContainer
            position="bottom-center"
            autoClose={10000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            icon={false}
            toastStyle={{ backgroundColor: "black", color: "white" }}
            closeButton={ToastCloseButton}
          />
           </PersistGate>
        </Provider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
