import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";

const PrivateRoutes = () => {
  const token = useAppSelector((state) => state.user.token);
  const logoutClickedValue = useAppSelector(
    (state) => state?.user?.logoutClickedValue
  );
  const userStatus = useAppSelector(
    (state) => state?.user?.data?.currentStatus
  );
  const { pathname, search } = useLocation();

  if (logoutClickedValue) {
    localStorage.removeItem("redirectPath");
  } else {
    if(pathname?.includes("/update-plan") || pathname?.includes("/shop-exp")|| pathname?.includes("/shop-review-order") || pathname?.includes("/change-plan-failed") || pathname?.includes("plan-details?orderNumber")) {
      localStorage?.setItem("redirectPath", '/account-settings/plan-details');
       if(userStatus && userStatus?.toUpperCase() !=="ACTIVE") {      
        return <Navigate to="/account-settings/plan-details" />
      }
    }else if(pathname?.includes("/error") || pathname?.includes("/network-info")){
      localStorage?.setItem("redirectPath", '/home');
    } else {
      localStorage?.setItem("redirectPath", pathname + search);
    }
  }
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
